import { ua } from './modules/ua'
import { slider } from './modules/slider'
import inView from 'in-view'
import smoothscroll from 'smoothscroll-polyfill'
// import Swiper from 'swiper/bundle'

const klass = {
  active: 'is-active',
  fixed: 'is-fixed',
  view: 'is-view',
  selected: 'is-selected',
  hidden: 'is-hidden',
  eventNone: 'is-eventNone',
  landscape: 'is-landscape',
  current: 'is-current',
  large: 'is-large',
  nav: 'view-nav',
}

class App {
  constructor() {
    this.body = document.querySelector('body')
    this.init()
  }

  init() {
    this.initPages()
  }

  initPages() {
    // 全ページ共通適用
    this.body.classList.add(`is-${ua.browser()}`)
    this.body.classList.add(`is-${ua.os()}`)
    this.setFillHeight()
    this.setViewMode()

    // ナビゲーション開閉
    document.querySelector('.nav-trigger').addEventListener('click', () => {
      document.querySelector('body').classList.toggle(klass.nav)
    })

    // ナビゲーションを押下時に閉じる
    Array.from(document.querySelectorAll('.gNav a')).forEach((trigger) => {
      trigger.addEventListener('click', () => {
        document.querySelector('body').classList.toggle(klass.nav)
      })
    })

    inView('.inview').on('enter', (el) => {
      el.classList.add('is-view')
    })
    inView.offset(100)

    /**
     * CSS変数 --vh セット
     * ※画面横幅が変わった場合のみ動作
     */
    let vw = window.innerWidth
    window.addEventListener('resize', () => {
      this.setViewMode()
      if (vw === window.innerWidth) {
        return
      }
      vw = window.innerWidth
      this.setFillHeight()
    })

    window.addEventListener('scroll', () => {
      if (window.scrollY > 200) {
        document.querySelector('body').classList.add('is-scroll')
      } else {
        document.querySelector('body').classList.remove('is-scroll')
      }
    })

    if (this.body.classList.contains('single-works')) {
      slider('.works-images__main', '.works-thumbnails')
    }
  }

  /**
   * CSS変数設定 画面高さの取得からvhを設定
   * ※iosの設定向け
   */
  setFillHeight() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  /**
   * 表示モード指定
   */
  setViewMode() {
    const mode = window.innerWidth < window.innerHeight ? 'is-landscape' : 'is-portrait'
    document.querySelector('body').classList.add(mode)
  }
}

document.addEventListener('DOMContentLoaded', () => {
  window.wanima = new App()

  smoothscroll.polyfill()
  Array.from(document.querySelectorAll('.js-smooth-scroll')).forEach((link) => {
    link.addEventListener('click', (e) => {
      const ankerTarget = e.target
      e.preventDefault()

      document.querySelector('body').classList.remove('view-nav')

      const targetId = ankerTarget.hash

      if (!document.querySelector(targetId)) {
        return false
      }

      const targetElement = document.querySelector(targetId)
      const rectTop = targetElement.getBoundingClientRect().top
      const offsetTop = window.pageYOffset
      const buffer = document.querySelector('.top-nav').clientHeight - 20
      const top = rectTop + offsetTop - buffer
      window.scrollTo({
        top,
        behavior: 'smooth',
      })
    })
  })
})

window.addEventListener('load', () => {
  document.querySelector('.loader').classList.add(klass.hidden)
})
