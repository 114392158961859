const slider = (dom, thumbnails) => {
  // 初期値
  let _slide = {
    dom: $(dom),
    img: $(`${dom} > .img`),
    current: $(dom).data('current'),
    count: {
      value: 1,
      // dom: $$(`${dom} .count`),
    },
    thumbnails: $(thumbnails),
    length: $(`${thumbnails} li`).length,
    btn: {
      all: $(`${dom} button`),
      next: $(`${dom} .btn-next`),
      prev: $(`${dom} .btn-prev`),
    },
  }
  //
  // // スライドの合計数を取得表示
  // // _slide.count.dom.find('.total').html(_slide.length)
  // _slide.btn.all.on('click touch', () => {
  //   let _type = $(this).hasClass('btn-next') ? 'next' : 'prev'
  //   _slide.current = _type === 'next' && _slide.length > _slide.current ? _slide.current + 1 : _slide.current - 1 // 次の写真番号を代入
  //   slideChange()
  // })

  Array.from(document.querySelectorAll(`${thumbnails} .img`)).forEach((img, idx) => {
    img.addEventListener('click', () => {
      _slide.current = Number(img.getAttribute('data-index')) // 次の写真番号を代入
      slideChange()

      const bodyW = $('body').width()
      const $pos = bodyW > 767 ? 168 : (bodyW / 375) * 168
      const targetOffset = _slide.dom.offset().top
      $('html,body').animate({ scrollTop: targetOffset - $pos }, 500)
    })
  })
  _slide.thumbnails.find('.img')

  /**
   * スライドショー変更関数
   */
  const slideChange = () => {
    // _slide.count.dom.find('.current').html(_slide.current) // 現在の写真番号を差し替え
    // prev/nextボタンを表示指定
    if (_slide.length === _slide.current) {
      _slide.btn.next.addClass('is-disabled')
      _slide.btn.prev.removeClass('is-disabled')
    } else if (1 === _slide.current) {
      _slide.btn.next.removeClass('is-disabled')
      _slide.btn.prev.addClass('is-disabled')
    } else {
      _slide.btn.next.removeClass('is-disabled')
      _slide.btn.prev.removeClass('is-disabled')
    }

    // サムネイルをアクティブ化
    let _nextImg = ''
    $.each($('.works-thumbnails__item'), (ii, vv) => {
      let __this = $(vv)
      if (ii + 1 === _slide.current) {
        __this.find('.img').addClass('is-selected')
        _nextImg = __this.find('.img').data('img')
      } else {
        __this.find('.img').removeClass('is-selected')
      }
    })

    // メインイメージの変更
    $('.works-images__main').prepend('<img class="img" src="' + _nextImg + '" alt="">')

    let _prevImg = _slide.dom.find('.img:nth-of-type(2)')
    _prevImg.addClass('is-remove').fadeOut(300)

    setTimeout(() => {
      _prevImg.remove()
    }, 300)
  }

  /**
   * スワイプ時の画像変更
   * @param elem
   */
  const setSwipe = (elem) => {
    let t = document.querySelector(elem)
    let startX // タッチ開始 x座標
    let startY // タッチ開始 y座標
    let moveX // スワイプ中の x座標
    let moveY // スワイプ中の y座標
    let dist = 30 // スワイプを感知する最低距離（ピクセル単位）

    // タッチ開始時： xy座標を取得
    t.addEventListener('touchstart', (e) => {
      // e.preventDefault()
      startX = e.touches[0].pageX
      startY = e.touches[0].pageY
    })

    // スワイプ中： xy座標を取得
    t.addEventListener('touchmove', (e) => {
      // e.preventDefault()
      moveX = e.changedTouches[0].pageX
      moveY = e.changedTouches[0].pageY
    })

    // タッチ終了時： スワイプした距離から左右どちらにスワイプしたかを判定する/距離が短い場合何もしない
    t.addEventListener('touchend', (e) => {
      if (startX > moveX && startX > moveX + dist && _slide.current !== _slide.length) {
        // 右から左にスワイプ
        _slide.current = _slide.current + 1
        slideChange()
      } else if (startX < moveX && startX + dist < moveX && _slide.current !== 1) {
        // 左から右にスワイプ
        _slide.current = _slide.current - 1 // 次の写真番号を代入
        slideChange()
      }
    })
  }

  // スライドスワイプ
  setSwipe(`${dom} > .img`)
}

export { slider }
